<template>
  <div class="layout agency-layout">
    <agency-header />
    <agency-mobile-menu v-if="showMobileMenu"></agency-mobile-menu>

    <div class="layout-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import AgencyHeader from '@/components/agency/AgencyHeader.vue';
import AgencyMobileMenu from '@/components/agency/AgencyMobileMenu.vue';

export default {
  name: 'AgencyLayout',
  components: {
    AgencyHeader,
    AgencyMobileMenu,
  },
  computed: {
    showMobileMenu() {
      return this.$store.state.mobileMenuStatus;
    },
  },
};
</script>
